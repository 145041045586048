import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import useGoogleMapsContext from 'hooks/useGoogleMapsContext'
import { MdOutlineTouchApp } from 'react-icons/md'
import { MdAdsClick } from 'react-icons/md'

interface AutocompletePlacesProps {
  onSelectPlace: (place: any) => void
  currentAddress: string
}

const AutocompletePlaces: React.FC<AutocompletePlacesProps> = ({
  onSelectPlace,
  currentAddress = null
}) => {
  const { isGMLoaded, setIsLoaded } = useGoogleMapsContext()

  React.useEffect(() => {
    if (currentAddress) {
      setAddress(currentAddress)
    }
  }, [currentAddress])

  const [address, setAddress] = React.useState('')

  const formatGoogleAddress = (address: any) => {
    const fullAddress = {
      name: "",
      street: "",
      streetNumber: "",
      suburb: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    };

    address.forEach((component: any) => {
      if (component.types.includes("street_number"))
        fullAddress["streetNumber"] = component.long_name;
      if (component.types.includes("route"))
        fullAddress["street"] = component.long_name;
      if (component.types.includes("neighborhood"))
        fullAddress["suburb"] = component.long_name;
      if (component.types.includes("locality"))
        fullAddress["city"] = component.long_name;
      if (component.types.includes("administrative_area_level_1"))
        fullAddress["state"] = component.long_name;
      if (component.types.includes("country"))
        fullAddress["country"] = component.long_name;
      if (component.types.includes("postal_code"))
        fullAddress["zipCode"] = component.long_name;
      if (fullAddress["suburb"] == "" && component.types.includes("sublocality"))
        fullAddress["suburb"] = component.long_name;
    });
    return fullAddress;
  }

  const handleSelect = async (value: string) => {
    setAddress(value)

    try {
      const results = await geocodeByAddress(value)
      const latLng = await getLatLng(results[0])

      onSelectPlace({
        address: {
          ...formatGoogleAddress(results[0]?.address_components)
        },
        geometry: {
          location: {
            lat: () => latLng.lat,
            lng: () => latLng.lng
          }
        }
      })
    } catch (error) {
      console.error('Error selecting place:', error)
    }
  }

  console.log(isGMLoaded)

  return (
    <>
      {isGMLoaded && (
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <input
                className=' block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                {...getInputProps({
                  placeholder: 'Captura tu edificio, fraccionamiento o dirección'
                })}
                autoComplete='new-password'
              />
              <div>
                {suggestions.map((suggestion: any) => (
                  <div
                    className='cursor-pointer text-sm	hover:bg-sky-100 p-2 flex justify-between hover:bg-blue-50'
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}{' '}
                    <MdAdsClick
                      style={{
                        fontSize: '1.5rem'
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </>
  )
}

export default AutocompletePlaces

import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getSessionItem } from "utils/session";
import { notify } from "utils/notify";
import BasicField from "components/atoms/BasicField";
import useFlowInstance from "hooks/useFlowInstance";
import usePortalData from "hooks/usePortalData";
import BasicCheckBox from "components/atoms/BasicCheckBox";
import { stripeErrors } from "utils/constants/stripeErrors";
import { notNumberValues, removeNumbers } from "utils/validations";
function PaymentForm() {
  const element_options: any = {
    fields: { billingDetails: { address: "never" } },
    terms: { card: "never" },
  };
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [error, setError] = useState(null);
  const [cardHolderName, setCardHolderName] = useState<any>("");
  const [authorize, setAuthorize] = useState<any>(false);
  const [disableButton, setDisableButton] = useState<any>(false);
  const { updateFlowInstance, createFlowInstanceCheckout } = useFlowInstance();
  const { setNextStep } = usePortalData();

  const handleSubmit = async (event: any) => {
    setDisableButton(true);
    event.preventDefault();
    if (!stripe || !elements) {
      disableButton(false);
      return;
    }
    const element = elements.getElement(PaymentElement);

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: "",
        payment_method_data: {
          billing_details: {
            name: cardHolderName,
            address: {
              country: "MX",
              postal_code: "",
              state: "",
              city: "",
              line1: "",
              line2: "",
            },
          },
        },
      },
      redirect: "if_required",
    });

    if (result.error) {
      notify(
        "error",
        (stripeErrors[result.error.code] || "La tarjeta ha sido rechazada") +
          (stripeErrors[result.error.decline_code] || "")
      );
      setError(result.error.message);
      // Handle error
    } else if (result.setupIntent.status === "succeeded") {
      const response = await updateFlowInstance({
        payment: {
          paymentMethod: result.setupIntent.payment_method,
          customerId: getSessionItem("stripeCustomerId"),
          paymentMethodType: "card",
        },
      });
      if (response?.success) {
        if(getSessionItem("flowType") === "customer_payment"){
          await createFlowInstanceCheckout()
        }
        setNextStep();
      }
      // SetupIntent confirmation succeeded
    }
    setDisableButton(false);
  };

  const isSubmitDisabled = !stripe || !elements || error;

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-sm m-2 text-cyan-800  description_text">
        En este momento no se generará ningún cobro por la renta mensual; únicamente se hará una validación de la tarjeta con un cargo temporal de 1 peso. El primer cobro de tu servicio se realizará 30 días después de la instalación, ya que el primer mes es sin costo por la promoción vigente.
      </p>
      <br />
      <BasicField
        label="Nombre del titular"
        name={"cardHolderName"}
        placeholder="Nombre y Apellidos"
        value={cardHolderName}
        onChange={(e: any) => {
          let value = notNumberValues.includes(e.target.name)
            ? removeNumbers(e.target.value)
            : e.target.value;

          setCardHolderName(value);
        }}
        required={true}
      />
      <PaymentElement options={element_options} />
      <BasicCheckBox
        value={authorize}
        onChange={(e: any) => {
          setAuthorize(e.target.checked);
        }}
      >
        <p className="text-sm ml-2">
          Autorizo que Axtel haga el cargo mensual a mi tarjeta de crédito o
          débito.
        </p>
      </BasicCheckBox>
      <button
        disabled={!authorize || disableButton}
        className=" w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 primary-btn"
        type="submit"
      >
        REGISTRAR
      </button>
    </form>
  );
}

export default PaymentForm;

import React from "react";
import { HiOutlineClock, HiOutlineCalendarDays } from "react-icons/hi2";
import EmptyStateCard from "components/molecules/EmptyStateCard";
import SlotCard from "components/atoms/InstallationSlots/SlotCard";
import BasicDatePicker from "components/atoms/BasicDatePicker";
import { getSessionItem } from 'utils/session';
import { getStringDate, getUnixDate, convertUnixToDate, convertDateToFormat } from 'utils/date';

export default function InstallationSlotsComponent({
  slots,
  onSubmit,
  isToday,
  setIsToday,
  onDateChange,
  currentDate,
  minDate,
  startOperation,
  getPromotion,
}: {
  slots: any;
  onSubmit: any;
  isToday: boolean;
  setIsToday: any;
  onDateChange: any;
  currentDate: any;
  minDate: any;
  startOperation: any; 
  getPromotion: boolean;
}) {
  const [selectedSlot, setSelectedSlot] = React.useState<any>(null);
  const [disabled, setDisabled] = React.useState<any>(true);
  const operationStartDate = getSessionItem('operationStartDate');
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-12 flex gap-4 ">
        {startOperation && (
          <button
            type="submit"
            onClick={() => {
              setIsToday(true);
              setSelectedSlot(null);
            }}
            style={{ background: isToday ? "#eaf5ff" : "" }}
            className="  w-full lg:w-fit flex items-center text-sm  hover:bg-blue-50 text-sky-700 font-bold py-2 px-4 mt-3 rounded-xl disabled:opacity-50"
          >
            El DÍA DE HOY <HiOutlineClock className="ml-2" />
          </button>
        )}
        <button
          type="submit"
          onClick={() => {
            setIsToday(false);
            setSelectedSlot(null);
          }}
          style={{ background: !isToday ? "#eaf5ff" : "" }}
          className=" w-full lg:w-fit flex items-center text-sm  hover:bg-blue-50 text-sky-700 font-bold py-2 px-4 mt-3 rounded-xl disabled:opacity-50"
        >
          OTRO DÍA <HiOutlineCalendarDays className="ml-2" />
        </button>
      </div>

      <div className="col-span-12 lg:col-span-12">
        <hr className=" h-0.5 border-t-2 bg-neutral-100 opacity-100 dark:opacity-50" />
      </div>
      {!isToday && (
        <div className="col-span-12 lg:col-span-4 ">
          <BasicDatePicker
            value={currentDate}
            min={minDate}
            onChange={(e: any) => {
              onDateChange(e.target.value);
              setSelectedSlot(null);
            }}
          />
        </div>
      )}
      {getPromotion && !startOperation && (
        <div className="col-span-12 lg:col-span-12">
          <div className="text-gray-600 bg-blue-50 p-2 rounded-md mt-3" style={{fontSize: '0.8rem'}}>
            <div>
              Estamos en la etapa final para habilitar tu fraccionamiento/edificio.
            </div>
            <div>
              Contrata ahora, agenda tu cita y además obtén un segundo mes gratis*.
            </div>
            <br />
            <div style={{fontSize: '0.7rem'}}>
              *Segundo mes gratis se agregará posterior a la instalación. Promoción válida hasta <strong>{convertDateToFormat(operationStartDate, 'DD/MM/YYYY')}</strong>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-12 lg:col-span-12 ">
        <br />
        <div className="grid grid-cols-12 gap-4">
          {!slots?.length && (
            <div className="col-span-12 lg:col-span- ">
              <EmptyStateCard message="No existen horarios disponibles en este día" />
            </div>
          )}
          {slots?.map((slot: any, index: number) => (
            <div key={index} className="col-span-12 lg:col-span-4 ">
              <SlotCard
                isToday={isToday}
                slot={slot}
                isSelected={slot === selectedSlot}
                onSelect={(slot: any) => {
                  setSelectedSlot(slot);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-12 lg:col-span-12 flex justify-center gap-4 ">
        <button
          onClick={() => {
            const payload: any = {
              fromDateTime: selectedSlot?.FromDate,
              toDateTime: selectedSlot?.ToDate,
              type: selectedSlot?.type,
              distributionAreaNumber: selectedSlot?.distributionAreaNumber,
              employeeNumber: selectedSlot?.EmployeeNumber,
            };
            onSubmit(payload);
          }}
          disabled={!selectedSlot}
          type="submit"
          className=" w-full lg:w-2/6 flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50 primary-btn"
        >
          AGENDAR
        </button>
      </div>
    </div>
  );
}

import React from 'react';
import BasicCard from 'components/atoms/BasicCard';
import useFieldService from 'hooks/useFieldService';
import InstallationSlotsComponent from 'components/organisms/InstallationSlots/InstallationSlotsComponent';
import useFlowInstance from 'hooks/useFlowInstance';
import usePortalData from 'hooks/usePortalData';
import usePaynet from 'hooks/usePaynet';
import { getSessionItem } from 'utils/session';
import { getStringDate, getUnixDate, convertUnixToDate, diffDates } from 'utils/date';

export default function InstallationSlotsPage() {
  const [currentDate, setCurrentDate] = React.useState<any>('');
  const [isToday, setIsToday] = React.useState<any>(true);
  const [slots, setSlots] = React.useState<any>([]);
  const [minDate, setMinDate] = React.useState<any>(getStringDate());
  const [getPromotion, setGetPromotion] = React.useState<any>(false);
  const [startOperation, setStartOperation] = React.useState<any>(true);
  const { getAvailableSlots, reserveSlot } = useFieldService();
  const { updateFlowInstance } = useFlowInstance();
  const { setNextStep } = usePortalData();
  const { createStripeCustomer } = usePaynet();

  const flowType: any = getSessionItem('flowType');

  React.useEffect(() => {
    getStringDate();
    getStringDate('add', 1, 'days');
    let operationStartDate = getSessionItem('operationStartDate');
    let date = '';
    if (isToday) {
      date = getStringDate();
    } else {
      // suma un día a currentDate
      date = getStringDate('add', 1, 'days');
    }

    if (operationStartDate) {
      let currentDateUnix = getUnixDate(new Date().toISOString());
      let operationStartDateUnix = getUnixDate(operationStartDate)
      if (currentDateUnix < operationStartDateUnix) {
        const days = diffDates(operationStartDateUnix, currentDateUnix);
        if (days > 2) {
          setGetPromotion(true);
        }
        date = convertUnixToDate(operationStartDateUnix).split(' ')[0]
      }
    }
    setCurrentDate(date);
  }, [isToday]);

  React.useEffect(() => {
    let operationStartDate = getSessionItem('operationStartDate');
    if (operationStartDate) {
      let currentDateUnix = getUnixDate(new Date().toISOString());
      let operationStartDateUnix = getUnixDate(operationStartDate)
      if (currentDateUnix < operationStartDateUnix) {
        let date = convertUnixToDate(operationStartDateUnix).split(' ')[0]
        setIsToday(false);
        setStartOperation(false);
        setMinDate(date);
      }
    }
  }, []);

  async function getData() {
    const currentCoordinates = JSON.parse(
      getSessionItem('customerBasicInfo') || '{}'
    );
    const response = await getAvailableSlots({
      fromDateTime: currentDate,
      type: 'installation',
      location: {
        y: currentCoordinates.lat, // lat
        x: currentCoordinates.lng, // lng
      },
    });

    setSlots(response);
  }

  React.useEffect(() => {
    getData();
  }, [currentDate]);

  const handleSubmit = async (slot: any) => {
    const result: any = await reserveSlot(slot);
    const { firstName, lastName, email } = JSON.parse(
      getSessionItem('customerBasicInfo') || '{}'
    );
    if (result?.ResultCode === 'OK') {
      const payload = {
        installation: {
          slot: slot,
          reservationId: result.ReservationId,
        },
        ...(getPromotion && { messageCoupon: getPromotion }),
      };

      await updateFlowInstance(payload);
      await createStripeCustomer({ firstName, lastName, email });
      setNextStep();
    } else {
      getData();
    }
  };

  return (
    <div className='m-6 mt-20 lg:m-20'>
      <div className='flex justify-center h-screen'>
        <div className='w-full sm:w-2/3'>
          <BasicCard>
            <h3 className='text-2xl font-semibold primary_text'>
              ¿Cuándo te gustaría que instalemos tu servicio?
            </h3>
            <br />
            <InstallationSlotsComponent
              onSubmit={handleSubmit}
              slots={slots}
              isToday={isToday}
              setIsToday={setIsToday}
              onDateChange={(value: string) => {
                setCurrentDate(value);
              }}
              currentDate={currentDate}
              minDate={minDate}
              startOperation={startOperation}
              getPromotion={getPromotion}
            />
          </BasicCard>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BasicField from 'components/atoms/BasicField'
import BasicDialog from 'components/atoms/BasicDialog'
import Privacy from 'components/atoms/Privacy'
import { AutoCompleteXtremoPlaces } from 'components/atoms/AutoCompleteXtremoPlaces'

import GoogleMapComponent from '../GoogleMapComponent'
import BasicCheckBox from 'components/atoms/BasicCheckBox'
import { validateAddress } from 'utils/validations'
import LocationConfirmationDialog from './LocationConfirmationDialog'
import AddressApartmentsForm from './AddressApartmentsForm'
import CustomerDataForm from './CustomerDataForm'
import AddressHousingSocietyForm from './AddressHousingSocietyForm'
import AddressShoppingCentreForm from './AddressShoppingCentreForm'
export default function AddressForm({
  address,
  onChange,
  onPlaceSelect,
  onSubmit,
  onDragEnd,
  setAcceptanceDate,
  mapZoom
}: any) {
  const handleSelect = (place: any) => {
    let streetName = place?.address?.street
    let streetNumber = place?.address?.streetNumber
    if (
      place?.sourceAddressType === 'xtremo' &&
      place?.addressType === 'housingSociety'
    ) {
      streetName = ''
      streetNumber = ''
    }
    const address: any = {
      street: streetName,
      extNumber: streetNumber,
      suburb: place?.address?.suburb,
      city: place?.address?.city,
      state: place?.address?.state,
      country: place?.address?.country,
      zipCode: place?.address?.zipCode,
      lat: place?.geometry?.location?.lat,
      lng: place?.geometry?.location?.lng,
      sourceAddressType: place?.sourceAddressType,
      ...(place?.sourceAddressType === 'xtremo' && {
        xtremoAddressProps: {
          params: place?.params,
          addressType: place?.addressType,
          name: place?.name,
          operationStartDate: place?.operationStartDate,
          xtremoAddressId: place?.xtremoAddressId
        }
      }),
      googleMapsPlaceId: place?.googleMapsPlaceId
    }
    onPlaceSelect(address)
  }
  const [openDialog, setOpenDialog] = React.useState(false)
  const [checkPrivacy, setCheckPrivacy] = React.useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  const handleOnReset = () => {
    setCheckPrivacy(false)
    onPlaceSelect({
      street: '',
      extNumber: '',
      intNumber: '',
      suburb: '',
      city: '',
      state: 'Nuevo León',
      zipCode: '',
      country: 'México',
      lat: '',
      lng: '',
      sourceAddressType: '',
      googleMapsPlaceId: '',
      xtremoAddressProps: {},
      ...(address.floor && { floor: '' }),
      ...(address.building && { building: '' })
    })
  }

  React.useEffect(() => {
    if (checkPrivacy) {
      setAcceptanceDate(new Date().toISOString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPrivacy])
  return (
    <form
      action='submit'
      onSubmit={(e: any) => {
        e.preventDefault()
        if (
          address?.sourceAddressType === 'xtremo' &&
          (address?.xtremoAddressProps.addressType === 'apartmentsBuilding' ||
            address?.xtremoAddressProps.addressType === 'shoppingCentre')
        ) {
          onSubmit()
        } else {
          setOpenConfirmDialog(true)
        }
      }}
    >
      <LocationConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        handleChange={(value: any) => {
          let info = { ...address, lat: value.lat, lng: value.lng }
          console.log(info)
          onPlaceSelect(info)
        }}
        address={address}
        onConfirm={onSubmit}
        coordinates={{ lat: address?.lat, lng: address?.lng }}
      />
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openDialog}
        closeDialog={() => {
          setOpenDialog(false)
        }}
      >
        <Privacy></Privacy>
      </BasicDialog>
      <div className='grid grid-cols-12 gap-2 lg:pl-5 lg:pr-5  '>
        <div className='col-span-12 lg:col-span-12'>
          <h2 className='font-semibold text-2xl primary_text '>
            Indícanos la dirección para el Servicio de Internet
          </h2>
          <br />
          <h4 className='font-semibold'>Captura tu edificio, fraccionamiento o dirección: </h4>
          {address?.sourceAddressType === 'xtremo' && (
            <h4 className='text-2xl mt-2 font-bold'>
              {address.xtremoAddressProps.name}
            </h4>
          )}
        </div>
        <div className='col-span-12 lg:col-span-12'>
          <AutoCompleteXtremoPlaces
            onSelectPlace={handleSelect}
            onReset={handleOnReset}
            defaults={
              address.sourceAddressType && {
                defaultValue: address.googleMapsPlaceId,
                sourceAddressType: address.sourceAddressType,
                defaultOptions: [
                  {
                    name: address?.xtremoAddressProps?.name || '',
                    description: `${address.street} ${address.extNumber}, ${address.suburb}, ${address.city}, ${address.state}, ${address.zipCode}, ${address.country}`,
                    sourceAddressType: address.sourceAddressType,
                    place_id: address.googleMapsPlaceId,
                    ...(address?.xtremoAddressProps && {
                      xtremoAddressProps: address.xtremoAddressProps
                    })
                  }
                ]
              }
            }
          />
        </div>
        {address?.sourceAddressType === 'xtremo' &&
          address?.xtremoAddressProps?.addressType === 'apartmentsBuilding' &&
          address?.xtremoAddressProps?.params && (
            <AddressApartmentsForm address={address} onChange={onChange} />
          )}
        {address?.sourceAddressType === 'xtremo' &&
          address?.xtremoAddressProps?.addressType === 'housingSociety' &&
          address?.xtremoAddressProps?.params && (
            <AddressHousingSocietyForm address={address} onChange={onChange} />
          )}
        {address?.sourceAddressType === 'xtremo' &&
          address?.xtremoAddressProps?.addressType === 'shoppingCentre' &&
          address?.xtremoAddressProps?.params && (
            <AddressShoppingCentreForm address={address} onChange={onChange} />
          )}
        {address?.sourceAddressType === 'google' && (
          <>
            <div className='col-span-12 lg:col-span-12'>
              <BasicField
                label='Calle'
                name='street'
                value={address?.street}
                onChange={onChange}
              />
            </div>
            <div className='col-span-12 lg:col-span-6'>
              <BasicField
                label='No. Exterior'
                required={true}
                name='extNumber'
                value={address?.extNumber}
                onChange={onChange}
              />
            </div>
            <div className='col-span-12 lg:col-span-6'>
              <BasicField
                label='No. Interior'
                name='intNumber'
                value={address?.intNumber}
                onChange={onChange}
              />
            </div>
            <div className='col-span-12 lg:col-span-12'>
              <BasicField
                label='Colonia'
                name='suburb'
                value={address?.suburb}
                onChange={onChange}
              />
            </div>
            <div className='col-span-12 lg:col-span-12'>
              <BasicField
                label='Código Postal'
                name='zipCode'
                maxLength='5'
                value={address?.zipCode}
                onChange={onChange}
              />
            </div>
          </>
        )}
      </div>
      <div className='block sm:hidden col-span-12 lg:col-span-12'>
        <div className='w-full border border-gray-200 rounded-lg shadow-sm h-fit'>
          <GoogleMapComponent
            isDraggable={false}
            initialPosition={{
              lat: address?.lat || 25.67507,
              lng: address?.lng || -100.31847
            }}
            zoom={mapZoom}
            onMarkerDragEnd={(value: any) => {
              onDragEnd(value)
            }}
          />
        </div>
      </div>
      {address.lat && address.lng && (
        <div className='grid grid-cols-12 gap-2 lg:pl-5 lg:pr-5 mt-3'>
          <CustomerDataForm address={address} onChange={onChange} />
          <div className='col-span-12 lg:col-span12'>
            <BasicCheckBox
              value={checkPrivacy}
              onChange={(e: any) => {
                setCheckPrivacy(e.target.checked)
              }}
            >
              <p className='text-sm ml-2'>
                He leído y acepto el{' '}
                <a
                  onClick={() => {
                    setOpenDialog(true)
                  }}
                  className=' cursor-pointer link_text	'
                >
                  <strong>Aviso de Privacidad</strong>
                </a>
              </p>
            </BasicCheckBox>
          </div>
          <div className='col-span-12 lg:col-span-12'>
            <button
              disabled={
                !address.lat ||
                !address.lng ||
                !checkPrivacy ||
                !validateAddress(address)
              }
              type='submit'
              className=' w-full lg:w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50 primary-btn'
            >
              CONTINUAR
            </button>
          </div>
        </div>
      )}
    </form>
  )
}
